.menu {
  border-left: 1px solid rgba(225, 225, 225, 0.2);
  cursor: pointer;
  &.right {
    border-right: 1px solid rgba(225, 225, 225, 0.2);
  }

  .menu-button {
    svg {
      margin-left: 6px;
    }

    .menu-icon {
      display: none;
    }
    span {
      display: block;
      color: #fff;
    }
  }

  .menu-dropdown {
    display: none;
    position: absolute;
    background: #fff;
    top: 50px;
    width: auto;
    margin-left: -10px;
    border-radius: 0 0 10px 10px;
    z-index: 500;
    overflow: hidden;
    cursor: pointer;

    &.show {
      display: flex !important;
    }

    .title {
      position: relative;
      padding-left: 20px;
      width: 210px;
      border-right: 1px solid #e3e3e3;

      h1 {
        font-size: 24px;
        font-weight: 400;
      }

      .version {
        display: block;
        padding: 5px;
        font-size: 10px;
        color: #e3e3e3;
      }
    }

    .links {
      z-index: 500;
      width: 100%;

      a {
        color: $grey75;
        padding: 12px 20px 12px 10px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #f9f9f9;
        cursor: pointer;

        svg {
          color: $grey75;
          margin-right: 13px;
          width: 20px;
          height: 20px;
          font-size: 12px !important;
        }

        &:hover {
          background: $extra-light-prurpe;
          color: #000;
        }
      }

      .version {
        color: $grey75;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #ccc;
      }
    }
  }
}
