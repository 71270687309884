@import 'scss/colors';
@import 'scss/equalrate';
@import 'scss/custom';
@import 'scss/timefilter.scss';

#systemMessages {
  top: 10px;
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  justify-content: center;
  z-index: 1000;
}

.system-messages {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: 2;
}

.btn.country {
  border: 0;
  background: var(--primary-color);
  padding: 6px 10px;
  min-width: 80px;
  color: white;
  border-radius: 0;
}

// components
@import '../components/form/CustomDatePicker/style';
@import '../components/materials/style';
@import '../components/Header/style';
@import '../components/Header/Menu/style';
@import '../components/URLSwitch/style';
@import '../components/UserMenu/style';
@import '../components/HeadLine/style';
@import '../components/Notifications/style';
@import '../components/Footer/style';
@import '../components/UserProfile/style';
@import '../components/Table/style';

// pages
@import '../pages/PromoCode/style';
@import '../pages/BrowsingHistory/style';
@import '../pages/Auth/style';
@import '../pages/Transactions/style';
