header {
  height: 50px;
  background: var(--primary-color);
  .project {
    .brand {
      width: 220px;
      box-sizing: border-box;

      img {
        height: 40px;
        padding-left: 20px;
      }
    }
  }
}

.navigation {
  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;

    svg {
      margin-right: 6px;
    }

    &:hover {
      background: rgba(var(--primary-color), 0.1);
    }
  }
}
